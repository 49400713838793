import {
  Button,
  Counter,
  Image,
  ImageProps,
  TriggerButton,
  TriggerEvent,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React, { useCallback, useState } from 'react';
import { SxStyleProp } from 'theme-ui';

import { Image as ImageType } from '@AuroraTypes';
import { useModalBasedOnFeatureFlag } from '@Components/Modal/useModalBasedOnFeatureFlag';
import { ProductCardGallery } from '@Components/ProductCardGallery/ProductCardGallery';
import { useFeatureFlag } from '@Core/octopus/useFeatureFlag';
import {
  DOMInteractionEvent,
  trackImageGalleryEvent,
  useInteractionTracking,
} from '@Core/tracking/hooks/useInteractionTracking';
import { Pictogram } from '@DesignSystemComponents/Pictogram';

interface ImageGalleryProps {
  images: ImageType[];
  width: ImageProps['width'];
  height?: ImageProps['height'];
  lazyImage?: boolean;
  showFullScreenButton?: boolean;
  trackingObject?: {
    name: string;
    masterId: string | number;
  };
  name: string;
  shouldUseModalWithHash?: boolean;
}

const navigationButtonStyles: SxStyleProp = {
  display: 'none',
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 'card',
  userSelect: 'none',
};

export const ImageGallery: React.FC<ImageGalleryProps> = ({
  images,
  width,
  height,
  lazyImage = true,
  showFullScreenButton = true,
  trackingObject,
  name,
  shouldUseModalWithHash,
}) => {
  const [imageIndex, setImageIndex] = useState(0);
  const [isGalleryModalOpen, setGalleryOpen, setGalleryClose] = useModalBasedOnFeatureFlag(
    shouldUseModalWithHash || false,
    'gallery',
  );
  const { t } = useTranslation();
  const newProductCardGallery = useFeatureFlag('NewProductCardGallery');

  const label = newProductCardGallery ? 'gallery-grid-open-masonry-grid' : 'open_carousel';
  const setGalleryOpenRef = useInteractionTracking({
    event: DOMInteractionEvent.CLICK,
    action: 'ImageGallery',
    label,
  });

  const changeImage = useCallback(
    (e: TriggerEvent, direction: number) => {
      e.stopPropagation();
      e.preventDefault();
      // use modulus to wrap around from first to last and vice versa
      setImageIndex((imageIndex) => {
        const newIndex = (imageIndex + images.length + direction) % images.length;

        trackImageGalleryEvent({
          event: e.type === 'click' ? DOMInteractionEvent.CLICK : DOMInteractionEvent.KEYUP,
          label: `change-image-${direction < 0 ? 'prev' : 'next'}`,
          position: newIndex,
          ...trackingObject,
        });

        return newIndex;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setImageIndex],
  );

  return (
    <div
      sx={{
        width: '100%',
        height: '100%',
      }}
    >
      <TriggerButton
        aria-label={t('seeAllPhotos')}
        onTrigger={setGalleryOpen}
        ref={setGalleryOpenRef}
        sx={{
          display: 'block',
          width: '100%',
          height: '100%',
          position: 'relative',
          '&:hover, &:focus': {
            '[data-id=image-gallery-button], [data-id=image-counter]': {
              display: [null, 'inline-flex'],
            },
          },
        }}
      >
        {showFullScreenButton && (
          <Pictogram
            data-id="image-gallery-expand"
            size="28"
            name="Actions/FullScreen"
            backgroundColor="backgroundPrimarylight"
            sx={{
              position: 'absolute',
              top: '3xs',
              right: '3xs',
              zIndex: 'card',
            }}
          />
        )}
        <div
          sx={{
            display: 'block',
            width: '100%',
            height: '100%',
          }}
        >
          <Button
            data-id="image-gallery-button"
            variant="Secondary"
            size="36"
            icon="Actions/MovePrevious"
            onClick={(e) => changeImage(e, -1)}
            ariaLabel={t('carousel.previous')}
            sx={{
              ...navigationButtonStyles,
              left: '3xs',
            }}
          />
          <Image
            src={images?.[imageIndex].url}
            alt={images?.[imageIndex].description}
            width={width}
            height={height}
            lazy={lazyImage}
            sx={{
              height: '100%',
            }}
            quality={70}
          />
          <Button
            data-id="image-gallery-button"
            variant="Secondary"
            size="36"
            icon="Actions/MoveNext"
            onClick={(e) => changeImage(e, +1)}
            ariaLabel={t('carousel.next')}
            sx={{
              ...navigationButtonStyles,
              right: '3xs',
            }}
          />
          <Counter
            size="28"
            data-id="image-counter"
            variant="Light"
            sx={{
              position: 'absolute',
              bottom: 'xs',
              left: '50%',
              transform: 'translateX(-50%)',
            }}
            currentCount={imageIndex + 1}
            total={images.length}
          />
        </div>
      </TriggerButton>

      {isGalleryModalOpen && (
        <ProductCardGallery
          setGalleryClose={setGalleryClose}
          images={images}
          imageIndex={imageIndex}
          name={name}
        />
      )}
    </div>
  );
};
