import { UnfilterTag } from '@loveholidays/design-system';
import React, { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useQuery } from 'urql';

import { Query } from '@AuroraTypes';
import { isQueryResolved } from '@Core/isQueryResolved';
import { URLQueryParams } from '@Core/url/URLQueryParams';
import getQuickLinks from '@Pages/search-results/getQuickLinks.gql';
import { useSearchSelectionStore } from '@Stores/StoreContext';

function useQuickLink() {
  const { search } = useLocation();
  const [{ data, fetching }] = useQuery<Query>({ query: getQuickLinks });

  return useMemo(() => {
    if (!isQueryResolved<Query>(fetching, data)) {
      return;
    }

    const quickLinks = data.Content?.searchResultPage?.quickLinks;
    const quickLinkParameter = new URLQueryParams(search).get('quickLink');

    if (!quickLinkParameter || !quickLinks) {
      return;
    }

    return quickLinks.find(({ normalisedTitle }) => normalisedTitle === quickLinkParameter);
  }, [data, fetching, search]);
}

export const QuickLinks: React.FC<{ className?: string }> = ({ className }) => {
  const quickLink = useQuickLink();
  const history = useHistory();
  const deselect = useSearchSelectionStore((state) => state.deselectSearchSelection);

  const onClick = () => {
    history.replace(window.location.pathname + window.location.search);

    if (quickLink?.searchSelection) {
      deselect(quickLink.searchSelection);
    }
  };

  if (!quickLink) {
    return null;
  }

  return (
    <div
      className={className}
      sx={{ minHeight: '36px' }}
      data-id="quick-links"
    >
      <UnfilterTag
        as="button"
        onTrigger={onClick}
        sx={{
          display: 'inline-flex',
          backgroundColor: 'backgroundBlack',
          borderColor: 'backgroundBlack',
          color: 'textLight',
          svg: {
            color: 'textLight',
          },
          '&:hover': {
            backgroundColor: 'backgroundBlack',
            borderColor: 'backgroundBlack',
            color: 'textLight',
            svg: {
              color: 'textLight',
            },
          },
        }}
      >
        {quickLink.title}
      </UnfilterTag>
    </div>
  );
};
