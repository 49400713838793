import { PageType } from '@AuroraTypes';
import { useAppContext } from '@Contexts/contexts';
import { useSearchSelectionStore } from '@Stores/StoreContext';

const isClient = typeof window !== 'undefined';

export const useSendSearchSelectionToApp = (pageType: PageType) => {
  const { isWebView } = useAppContext();

  useSearchSelectionStore((state) => {
    if (isClient && isWebView && window.sendFrontierEvent) {
      // Pass the latest search selection to frontier, so it can
      // be used to set the search selection when webview changes
      window.sendFrontierEvent({
        name: 'searchSelectionChange',
        message: state.toUrl(pageType),
      });
    }
  });
};
